<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { getAuth } from "firebase/auth";

const auth = getAuth();
const currentUser = auth.currentUser;

const showDropdown = ref(false);
const dropdownRef = ref(null);
const buttonRef = ref(null);

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const closeDropdown = (event) => {
  if (
    dropdownRef.value &&
    !dropdownRef.value.contains(event.target) &&
    buttonRef.value &&
    !buttonRef.value.contains(event.target)
  ) {
    showDropdown.value = false;
  }
};

const userInitials = computed(() => {
  if (currentUser && currentUser.email) {
    const email = currentUser.email;
    const initials = email.substring(0, 2).toUpperCase();
    return initials;
  }
  return null;
});

const disconnect = async () => {
  await signOutUser();
  showDropdown.value = false;
};

onMounted(() => {
  document.addEventListener("click", closeDropdown);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", closeDropdown);
});
</script>

<template>
  <div class="relative" ref="buttonRef">
    <button
      @click="toggleDropdown"
      class="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full text-gray-800 font-medium text-sm font-inter"
    >
      <div>
        {{ userInitials }}
      </div>
    </button>

    <!-- Dropdown Menu -->
    <div
      v-if="showDropdown"
      class="absolute right-0 z-20 w-32 bg-white border rounded-md shadow-lg"
      ref="dropdownRef"
    >
      <ul>
        <li>
          <button
            @click="disconnect"
            class="block w-full font-inter font-semibold text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            Sign Out
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
